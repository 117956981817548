<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        나의 고객관리
        <v-tooltip
          right
          color="black"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              class="mt-1 ml-1"
              v-bind="attrs"
              v-on="on"
              @click="getSummary()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <div>
        <v-btn
          depressed
          color="success"
          @click="customerRegistDlg.show = true; customerRegistDlg.data = {
            name: null,
            phone: null,
            sourceName: null,
          }"
          v-if="checkFuncPermission('regist')"
        >
          고객등록
        </v-btn>
      </div>
    </div>
    <div class="main-section">
      <search-filter
        :search="search"
        :radioFilters="radioFilters"
        :selectFilters="selectFilters"
        type="server"
        searchPlaceHolder="고객명/전화번호/메모"
        @updateSearch="updateSearch"
        @updateRadio="updateRadio"
        @updateSelect="updateSelect"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col class="d-flex align-center pl-0">
          <p class="mb-0 mt-4">
            <v-chip
              color="primary"
              label
              outlined
              class="pr-2 pl-2 mr-4"
            >
              총: {{ totalItems.toLocaleString('ko-KR') }} 건
            </v-chip>
          </p>
          <v-radio-group
            @change="itemsPerPage = $event"
            dense
            row
            :value="itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <div
          class="edit-btn-wrap d-flex"
          v-if="checkFuncPermission('sms') === true"
        >
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on }">
              <v-chip
                v-on="on"
                label
                link
                class="pl-3 d-flex align-center"
                depressed
                :disabled="loading === true || totalItems <= 0"
                color="primary"
                @click="smsDlgOpenAll()"
              >
                <v-icon
                  size="20"
                  class="mr-2"
                >mdi-email-multiple-outline</v-icon>
                전체 문자발송
              </v-chip>
            </template>
            <span>
              조회한 조건 중에서 가망 고객에게 문자를 발송합니다.<br />
            </span>
          </v-tooltip>
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on }">
              <v-chip
                v-on="on"
                label
                link
                class="pl-2 ml-2 d-flex align-center"
                depressed
                :disabled="loading === true || selected.length === 0"
                color="info"
                @click="smsDlgOpenSelected()"
              >
                <v-icon
                  size="20"
                  class="mr-2"
                >mdi-email-check-outline</v-icon>
                선택 문자발송({{
                  selected.filter((item) => item.status === 1).length
                }})
              </v-chip>
            </template>
            <span>
              선택한 고객 중에서 가망 고객에게 문자를 발송합니다.<br />
            </span>
          </v-tooltip>
          <v-chip
            label
            link
            class="pl-2 ml-2 d-flex align-center"
            depressed
            :disabled="loading === true || selected.length === 0"
            color="success"
            @click="
              memoDlg({
                show: true,
                data: [],
                target: {},
                batch: selected.map((row) => row.id),
                func: () => { getSummary(false) },
              });
            "
          >
            <v-icon
              size="20"
              class="mr-2"
            >mdi-pencil</v-icon>
            메모일괄등록({{
              selected.length
            }})
          </v-chip>
        </div>
      </v-row>
      <data-table
        :items="lists"
        :sorts.sync="sorts"
        :headers="headers"
        :selectable="checkFuncPermission('sms') === true"
        :selected.sync="selected"
        itemKey="id"
        :returnObject="true"
        :loading="loading"
      >
        <template #amount="{ item }">
          <div class="d-flex align-center justify-center">
            <v-chip
              v-if="item.serviceStart !== null && item.serviceEnd !== null"
              label
              color="success"
              small
              class="pr-1 pl-1 mr-1"
            >
              유료
            </v-chip>
            <v-chip
              label
              small
              class="pr-1 pl-1 mr-1"
              v-else
            >
              무료
            </v-chip>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <v-chip
                  label
                  color="info"
                  link
                  small
                  class="pr-0 pl-0 d-flex justify-center"
                  style="width: 43px;"
                  v-on="on"
                  v-if="item.status === 1"
                  @click="updateCustomerData({
                    items: [{
                      id: item.id,
                      status: 0,
                    }]
                  }, '비가망으로 변경되었습니다.')"
                >
                  가망
                </v-chip>
                <v-chip
                  label
                  small
                  outlined
                  link
                  class="pr-0 pl-0 d-flex justify-center"
                  style="width: 43px;"
                  v-on="on"
                  v-else
                  @click="updateCustomerData({
                    items: [{
                      id: item.id,
                      status: 1,
                    }]
                  }, '가망으로 변경되었습니다.')"
                >
                  비가망
                </v-chip>
              </template>
              <span>{{ item.status === 1 ? '비가망' : '가망' }}으로 변경</span>
            </v-tooltip>
          </div>
        </template>
        <template #serviceEnd="{ item }">
          <div class="d-flex align-center justify-center">
            <span v-if="item.serviceStart !== null && item.serviceEnd === null">
              가결제
            </span>
            <span v-else-if="
                item.serviceStart !== null
                && time.moment(item.serviceEnd).diff(time.moment(), 'days') >= 0
              ">
              {{ time.moment(item.serviceEnd).diff(time.moment(), 'days') + 1 }}일
            </span>
            <span
              v-else
              class="grey--text"
            >없음</span>
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  @click="getHistories(
                    'users.rooms.update',
                    'rooms',
                    '서비스 변경이력',
                    item,
                  )"
                >
                  <v-icon size="18px">mdi-clock-time-two-outline</v-icon>
                </v-btn>
              </template>
              <span>서비스이력 열람</span>
            </v-tooltip>
          </div>
        </template>
        <template #sourceName="{ item }">
          <span
            v-if="item.sourceName === null"
            class="gray--text"
          >
            미등록
          </span>
          <span>{{ item.sourceName }}</span>
        </template>
        <template #name="{ item }">
          <div class="d-flex align-center cursor-pointer flex-wrap">
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{`
                    ${item.name}
                    (${item.phone.slice(-4)})
                  `}}
                </span>
              </template>
              <span>{{ item.phone }}</span>
            </v-tooltip>
            <v-menu
              offset-y
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  @click="customerName = item.name"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-pencil</v-icon>
                </v-btn>
              </template>
              <div class="text-input">
                <v-text-field
                  :autofocus="true"
                  solo
                  :value="item.name"
                  dense
                  flat
                  hide-details
                  placeholder="이름(2 ~ 10자)"
                  @input="customerName = $event"
                  @keydown="if($event.key === 'Enter') {
                    updateCustomerName(item, customerName)
                  }"
                ></v-text-field>
                <v-btn
                  class="mt-1 pl-1 pr-1"
                  color="primary"
                  depressed
                  dense
                  small
                  @click="updateCustomerName(item, customerName)"
                >수정</v-btn>
                <v-btn
                  class="ml-1 mt-1 pl-1 pr-1"
                  color="info"
                  depressed
                  dense
                  small
                  @click="getHistories(
                    'users.update',
                    'name',
                    '고객명 변경이력',
                    item,
                  )"
                >변경이력</v-btn>
              </div>
            </v-menu>
          </div>
        </template>
        <template #manager="{ item }">
          <div class="d-flex align-center justify-left">
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <div
                  class="d-flex align-center cursor-pointer flex-wrap"
                  v-on="on"
                  v-bind="attrs"
                  @click="getHistories(
                    'customer.update',
                    'manager',
                    '영업담당자 변경이력',
                    item,
                  )"
                >
                  <v-chip
                    link
                    style="height: auto; white-space: pre-wrap;"
                    class="pl-1 pr-1 mr-1"
                    small
                    label
                    outlined
                  >{{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음') }}</v-chip>
                  {{ item.managerName }}
                </div>
              </template>
              <span>영업담당자 변경이력 열람</span>
            </v-tooltip>
          </div>
        </template>
        <template #memo="{ item }">
          <div class="d-flex align-center">
            <span
              v-if="item.memo != null && item.memo.length > 0"
              class="memo-span"
            >{{ item.memo[0].memo }}</span>
            <span
              v-else
              class="empty-data"
            >
              최근 메모 없음
            </span>
            <v-btn
              small
              icon
            >
              <v-icon
                small
                color="primary"
                @click="
                  memoDlg({
                    show: true,
                    data: item.memo,
                    target: {
                      name: `${item.name}(${item.phone.slice(-4)})`,
                      id: item.id,
                    },
                    func: () => { getSummary(false) },
                  });"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </template>
        <template #histories="{ item }">
          <div
            class="d-flex align-center justify-center"
            style="width: max-content"
          >
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  @click="getSmsList(item)"
                >
                  <v-icon size="18px">mdi-email-search-outline</v-icon>
                </v-btn>
              </template>
              <span>
                문자 이력
              </span>
            </v-tooltip>
          </div>
          <div
            class="d-flex align-center justify-center"
            style="width: max-content"
          >
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  @click="getCustomerHistory(item, 'cs.list.get')"
                >
                  <v-icon size="18px">mdi-headset</v-icon>
                </v-btn>
              </template>
              <span>
                상담 이력
              </span>
            </v-tooltip>
          </div>
          <div
            class="d-flex align-center justify-center"
            style="width: max-content"
          >
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  @click="getCustomerHistory(item, 'sales.list.get')"
                >
                  <v-icon size="18px">mdi-finance</v-icon>
                </v-btn>
              </template>
              <span>
                매출 이력
              </span>
            </v-tooltip>
          </div>
        </template>
        <template #assigned="{ item }">
          {{ time.makeLocalTime(item.assigned, 'min') }}
        </template>
        <template #contactDate="{ item }">
          <span v-if="item.contactDate != null">
            {{ time.makeLocalTime(item.contactDate, 'min') }}
          </span>
          <span
            class="gray--text"
            v-else
          >
            정보없음
          </span>
        </template>
      </data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / itemsPerPage) > 1"
          @click="page = 1"
          :disabled="page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="itemsPerPage > 0"
          small
          color="primary"
          v-model="page"
          :length="Math.ceil(totalItems / itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / itemsPerPage) > 1"
          @click="page = Math.ceil(totalItems / itemsPerPage)"
          :disabled="page === Math.ceil(totalItems / itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <dlg-customer-history
      :show.sync="customerHistoryDlg.show"
      :data="customerHistoryDlg.data"
      :title="customerHistoryDlg.title"
      :user="customerHistoryDlg.user"
      :command="customerHistoryDlg.command"
    />
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
      :sub="historyDlg.sub"
    />
    <v-dialog
      v-model="smsDlg"
      persistent
      width="800px"
      @click:outside="smsDlginit()"
      @keydown="$event.key === 'Escape' ? smsDlginit() : ''"
    >
      <v-card class="sms-wrap">
        <div class="d-flex">
          <div class="mid-section">
            <div class="d-flex align-center sub-tit justify-space-between">
              <div class="d-flex align-center">
                문자작성
                <v-chip
                  v-if="smsTemplate != null"
                  class="ml-2"
                  small
                  :color="
                    fileUtils.getByteLength(
                      `(광고)${smsTemplate.name}
                      \n${smsContent}\n무료거부${smsTemplate.reject}`
                    ) >= 2000
                    || smsContent.length <= 0
                    ? 'error' : 'success'"
                >
                  {{
                    fileUtils.getByteLength(
                      `(광고)${smsTemplate.name}
                      \n${smsContent}\n무료거부${smsTemplate.reject}`
                    )
                  }} / 2000 byte
                </v-chip>
              </div>
              <v-select
                v-if="smsTemplates.length > 0"
                style="font-size: .8rem; max-width: 160px;"
                :items="smsTemplates"
                item-text="name"
                return-object
                :value="smsTemplates[0]"
                dense
                outlined
                hide-details
                flat
                @change="smsTemplate = $event"
              />
            </div>
            <v-text-field
              :autofocus="true"
              style="font-size: 0.9rem"
              dense
              flat
              hide-details
              placeholder="제목(최대 40 byte)"
              color="black"
              v-model="smsSubject"
              class="mb-2"
              :rules="[
                fileUtils.getByteLength(smsSubject) > 40 ? false : true
              ]"
              outlined
              solo
            ></v-text-field>
            <div
              class="default-text mb-1"
              v-if="smsTemplate != null"
            >
              (광고){{ smsTemplate.name }}
            </div>
            <v-textarea
              v-model="smsContent"
              dense
              flat
              hide-details
              color="black"
              outlined
              auto-grow
              solo
              style="font-size: .9rem"
            />
            <div
              class="default-text mt-1"
              v-if="smsTemplate != null"
            >
              무료거부{{ smsTemplate.reject }}
            </div>
          </div>
          <div class="preview-section mid-section">
            <div class="d-flex align-center sub-tit pt-1">
              미리보기
            </div>
            <v-chip
              label
              small
              class="mb-3 pl-2 pr-2"
              color="success"
              v-if="smsTemplate != null"
            >
              발신번호: {{ makeTelNumber(smsTemplate.callback) }}
            </v-chip>
            <div
              class="preview-text mb-2"
              v-if="smsSubject.trim().length > 0"
            >
              {{ smsSubject }}
            </div>
            <div
              class="preview-text"
              v-if="smsTemplate != null"
            >{{
              `(광고)${smsTemplate.name}\n${smsContent}\n무료거부${smsTemplate.reject}`
            }}</div>
            <div class="d-flex justify-end mt-4">
              <v-btn
                v-if="smsTemplate != null"
                color="primary"
                elevation
                @click="sendSMS()"
                :disabled="
                  fileUtils.getByteLength(
                    `(광고)${smsTemplate.name}
                    \n${smsContent}\n무료거부${smsTemplate.reject}`
                  ) >= 2000
                  || fileUtils.getByteLength(smsSubject) > 40
                  || smsContent.length <= 0
                  || targets.length <= 0"
              >
                {{ targets.length.toLocaleString('ko-KR') }}건 발송
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="smsHistory.show"
      width="max-content"
    >
      <v-card class="log-wrap sms-log-wrap">
        <p class="tit">
          문자발송이력
          <span class="sub">{{ smsHistory.customer }}</span>
        </p>
        <div
          class="sms-log"
          v-if="smsHistory.data.length > 0"
        >
          <div
            v-for="(row, index) in smsHistory.data"
            :key="index"
            class="sms-log-row align-center"
          >
            <div style="width:max-content; flex-shrink:0;">
              <div class="d-flex align-center">
                <v-chip
                  color="success"
                  x-small
                  style="font-size: .7rem;"
                  class="pl-1 pr-1"
                  label
                  outlined
                >
                  발신
                </v-chip>
                <span class="ml-1">{{ makeTelNumber(row.callback) }}</span>
              </div>
              <div class="d-flex align-center">
                <v-chip
                  color="info"
                  x-small
                  style="font-size: .7rem;"
                  class="pl-1 pr-1"
                  label
                  outlined
                >
                  수신
                </v-chip>
                <span class="ml-1">{{ comm.makePrivatePhoneNumber(row.receiverPhone) }}</span>
              </div>

            </div>
            <div
              class="d-flex ml-4 align-center"
              style="
                font-size: .8rem;
                color: #555;
                width:300px;
                display: -webkit-box;
                text-align: left;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 2;
                line-height: 15px;
                max-height: 30px;
              "
            >
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    v-on="on"
                    style="
                      width:400px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    "
                  >{{ row.full }}</span>
                </template>
                <span style="white-space: pre-wrap;">{{
                  row.full
                }}</span>
              </v-tooltip>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on }">
                  <v-btn
                    v-on="on"
                    small
                    icon
                    v-clipboard:copy="row.full"
                    v-clipboard:success="() => {alert(['success', '문자내용을 복사하였습니다.'])}"
                    v-clipboard:error="() => {alert(['success', '복사에 실패하였습니다.'])}"
                  >
                    <v-icon
                      small
                      color="primary"
                    >
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template>
                <span>문자내용 복사하기</span>
              </v-tooltip>
            </div>
            <span
              class="ml-6"
              style="width:max-content; flex-shrink:0; white-space:pre-wrap"
            >{{
                `${comm.getGroupFullPath(groups, row.senderGroupNo, '그룹없음')}
                ${row.senderName}\n${time.makeLocalTime(row.created, 'sec')}`
              }}
            </span>
          </div>
        </div>
        <div
          class="log"
          v-else
        >
          <p class="log-row mr-16"><span>문자발송이력이 없습니다.</span></p>
        </div>
      </v-card>
    </v-dialog>
    <!-- 고객등록 모달 -->
    <v-dialog
      v-model="customerRegistDlg.show"
      scrollable
      max-width="500"
      @click:outside="customerRegistDlg.show = false; customerRegistDlg.data = {
        name: null,
        phone: null,
        sourceName: null,
      }"
    >
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between pt-6">
          <span class="text-h5">
            고객등록
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="question mb-4"
                  small
                  color="warning"
                  v-on="on"
                  v-bind="attrs"
                >mdi-help-circle-outline</v-icon>
              </template>
              <span>
                입력한 고객정보가 DB에 등록되며,<br />
                로그인한 계정이 영업담당자로 배정되어 고객관리에서 열람할 수 있습니다.
              </span>
            </v-tooltip>
          </span>
        </v-card-title>
        <v-card-text class="pb-0 content-wrap">
          <div class="content-box">
            <div class="section-wrap">
              <div class="section">
                <p class="tit">영업담당자</p>
                <div class="ml-2">
                  <span>
                    {{
                      `${comm.getGroupFullPath(
                        groups, userInfo.groupNo, '그룹없음'
                      )} ${userInfo.name}`
                    }}
                  </span>
                </div>
              </div>
              <div class="section">
                <p class="tit">이름</p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="customerRegistDlg.data.name"
                    outlined
                    dense
                    flat
                    label="이름 (1~10자)"
                    :rules="[
                      (value) => value === null
                      || value.trim().length < 1
                      || value.trim().length > 10
                      ? '1~10자로 입력해주세요.' : true
                    ]"
                    autofocus
                  />
                </div>
              </div>
              <div class="section">
                <p class="tit">전화번호</p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="customerRegistDlg.data.phone"
                    outlined
                    dense
                    flat
                    :rules="[
                      (value) => {
                        const reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
                        if (value === null) {
                          return true;
                        } else if (reg.test(value)) {
                          return true;
                        }
                        return '휴대전화번호 형식에 맞춰주세요.';
                      },
                    ]"
                    label="전화번호"
                  />
                </div>
              </div>
              <div class="section">
                <p class="tit">DB유입업체</p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="customerRegistDlg.data.sourceName"
                    outlined
                    dense
                    flat
                    label="유입업체명 (1~20자)"
                    :rules="[
                      (value) => value === null
                        || value.trim().length === 0
                        || value.trim().length <= 10
                        ? true : '10자 이하로 입력해주세요.'
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="registCustomer()"
            :disabled="customerRegistDlg.data.name === null
              || customerRegistDlg.data.name.trim().length <= 0
              || customerRegistDlg.data.name.trim().length > 10
              || (customerRegistDlg.data.sourceName !== null
              && customerRegistDlg.data.sourceName.trim().length > 10)
              || customerRegistDlg.data.phone === null||
              !/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
              .test(customerRegistDlg.data.phone)"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DlgCustomerHistory from '@/components/dialog/DlgCustomerHistory.vue';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import time from '@/util/time';
import comm from '@/util/comm';
import path from '@/util/path';
import fileUtils from '@/util/file';
import axios from 'axios';
import debounce from 'debounce';

export default {
  name: 'ManagerCustomer',
  components: {
    SearchFilter,
    DlgCustomerHistory,
    DataTable,
    DlgHistory,
  },
  data: () => ({
    comm,
    time,
    fileUtils,
    customerHistoryDlg: {
      show: false,
      data: [],
      user: null,
      command: null,
      title: null,
    },
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    groups: [],
    selected: [],
    loading: false,
    lists: [],
    search: '',
    totalItems: 0,
    page: 1,
    itemsPerPage: 50,
    sorts: {
      sortBy: ['assigned', 'id'],
      sortDesc: [true, true],
    },
    selectFilters: {
      sourceNo: {
        key: 'sourceNo',
        name: '유입업체',
        value: -1,
        data: [],
      },
    },
    currentItem: null,
    makeHistories: {
      manager: (data, staffs) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.manager !== undefined) {
            const { fullName } = staffs.find((row) => r.after.manager === row.id);
            const typeObj = {
              insert: '배정',
              update: '변경',
            };
            result.push({ ...r, after: `[${typeObj[r.after.type]}]${fullName}` });
          }
        });
        return result;
      },
      rooms: (data) => {
        const result = [];
        const typeObj = {
          update: '수정',
          sales: '매출승인',
          refund: '환불승인',
          preview: '가결제승인',
          sales_cancle: '매출취소',
        };
        data.forEach((r) => {
          if (r.after.rooms !== undefined) {
            result.push({
              ...r,
              after: `[${typeObj[r.after.type]}] 리딩방 ${r.after.rooms.length}개`,
              rooms: r.after.rooms,
            });
          }
        });
        return result;
      },
      name: (data) => {
        const result = [];
        const typeObj = {
          update: '수정',
          insert: '등록',
        };
        data.forEach((r) => {
          if (r.after.name !== undefined) {
            result.push({
              ...r,
              after: `[${typeObj[r.after.type]}] ${r.after.name}`,
            });
          }
        });
        return result;
      },
    },

    radioFilters: {
      amount: {
        key: 'amount',
        name: '유료여부',
        value: -1,
        data: [
          { name: '전체', value: -1 },
          { name: '무료', value: 0 },
          { name: '유료', value: 1 },
        ],
      },
      status: {
        key: 'status',
        name: '가망여부',
        value: -1,
        data: [
          { name: '전체', value: -1 },
          { name: '비가망', value: 0 },
          { name: '가망', value: 1 },
        ],
      },
      service: {
        key: 'service',
        name: '잔여이용기간',
        value: -1,
        data: [
          { name: '전체', value: -1 },
          { name: '없음', value: 0 },
          { name: '있음', value: 1 },
          { name: '가결제', value: 2 },
        ],
      },
    },
    smsDlg: false,
    smsContent: '',
    smsSubject: '',
    setManagerDlg: {
      show: false,
      sales: null,
    },
    smsTemplates: [],
    smsTemplate: null,
    targets: [],
    headers: [
      {
        text: '유료/가망',
        value: 'amount',
        align: 'center',
        width: 120,
        sortable: false,
      },
      {
        text: '잔여이용기간',
        value: 'serviceEnd',
        align: 'center',
        width: 140,
      },
      {
        text: '영업담당자',
        value: 'manager',
        align: 'left',
        width: 260,
      },
      {
        text: '고객정보',
        value: 'name',
        align: 'left',
        width: 200,
      },
      {
        text: '메모',
        value: 'memo',
        align: 'left',
        sortable: false,
      },
      {
        text: '문자/상담/매출이력',
        value: 'histories',
        align: 'center',
        width: 120,
        sortable: false,
      },
      {
        text: '유입업체',
        value: 'sourceName',
        align: 'center',
        width: 120,
      },
      {
        text: '담당자배정일시',
        value: 'assigned',
        align: 'center',
        width: 140,
      },
      {
        text: 'DB등록일시',
        value: 'contactDate',
        align: 'center',
        width: 140,
      },
    ],
    smsHistory: {
      show: false,
      data: [],
      customer: {
        name: '',
        phone: '',
      },
    },
    customerRegistDlg: {
      show: false,
      data: {
        name: null,
        phone: null,
        sourceName: null,
      },
    },
    customerName: '',
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      companyInfo: 'auth/companyInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
      memoDlg: 'dialog/memo',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 셀렉트필터 업데이트 */
    updateSelect(value, key) {
      this.selectFilters[key].value = value;
    },
    getSummary(reset = true) {
      if (reset === true) this.lists = [];
      this.selected = [];
      this.loading = true;
      const options = this.makeOptions();
      this.$socket.emit('users.customer.summary.get', {
        filters: options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.$socket.emit('users.customer.list.get', options, (response) => {
            if (response.result === 'success') {
              this.totalItems = resp.item.count;
              this.lists = Object.freeze(response.items);
              if (this.page > Math.ceil(this.totalItems / this.itemsPerPage)) {
                this.page = 1;
              }
              this.loading = false;
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
              console.error(resp);
              this.loading = false;
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    updateCustomerData(obj, msg) {
      this.$socket.emit('users.customer.update', obj, (resp) => {
        if (resp.result === 'success') {
          this.getSummary(false);
          this.alert(['success', msg]);
          this.confirm({ show: false });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.confirm({ show: false });
        }
      });
    },
    getCustomerHistory(item, command) {
      const commandObj = {
        'cs.list.get': {
          title: '상담이력',
          command: 'cs.get',
        },
        'sales.list.get': {
          title: '매출이력',
          command: 'sales.get',
        },
      };
      this.progress(true);
      this.$socket.emit(command, {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['created'],
        sortDesc: [true],
        filters: [
          { condition: 'eq', column: 'customer', value: item.id },
        ],
      }, (resp) => {
        if (resp.result === 'success') {
          let data = [];
          if (command === 'cs.list.get') {
            const statusArray = ['미확인', '진행중', '처리완료'];
            data = resp.items.map((r) => ({
              no: r.no,
              content: `[${statusArray[r.status]}]]${r.title}`,
              date: r.created,
              command: commandObj[command].command,
            }));
          } else if (command === 'sales.list.get') {
            const typeArray = ['매출', '환불'];
            const statusArray = ['미승인', '승인', '가결제'];
            data = resp.items.map((r) => ({
              no: r.no,
              content: `[${typeArray[r.type]}][${statusArray[r.status]}] ${comm.priceToString(r.amount)}`,
              date: r.created,
            }));
          }
          this.customerHistoryDlg = {
            show: true,
            data,
            user: `${item.name}(${item.phone.slice(-4)})`,
            title: commandObj[command].title,
            command: commandObj[command].command,
          };
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    smsDlgOpen(targets) {
      console.log(path, axios);
      this.targets = targets;
      this.smsTemplates = [{
        name: 'SAMPLE',
        reject: '00000000000',
        callback: '00000000',
      }];
      [this.smsTemplate] = this.smsTemplates;
      this.smsDlg = true;
      this.progress(false);
    },
    smsDlginit(isSend = false) {
      const func = () => {
        this.smsSubject = '';
        this.smsContent = '';
        this.smsDlg = false;
        this.confirm({ show: false });
      };
      if (isSend === false && this.smsContent.length > 0) {
        this.confirm({
          show: true,
          msg: '작성중인 내용이 삭제됩니다.<br/><br/>정말 창을 닫을까요?',
          func,
          btnText: '닫기',
          color: 'error',
        });
      } else {
        func();
      }
    },
    smsDlgOpenAll() {
      this.progress(true);
      this.$socket.emit('users.customer.list.get', {
        ...this.makeOptions(true),
        itemsPerPage: 0,
      }, async (resp) => {
        if (resp.result === 'success') {
          const items = JSON.parse(await fileUtils.buffersToJson(resp.items));
          if (items.length <= 0) {
            this.alert(['error', '조회한 조건 중 가망 고객이 없습니다.']);
            this.progress(false);
          } else {
            this.smsDlgOpen(items);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    smsDlgOpenSelected() {
      this.progress(true);
      const selectedFilter = this.selected.filter((item) => item.status === 1);
      if (selectedFilter.length <= 0) {
        this.alert(['error', '선택한 고객 중 가망 고객이 없습니다.']);
        this.progress(false);
      } else {
        this.smsDlgOpen(selectedFilter);
      }
    },
    getSmsList(target) {
      this.progress(true);
      this.$socket.emit('sms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['created'],
        sortDesc: [true],
        filters: [{ condition: 'eq', column: 'receiver', value: target.id }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.smsHistory = {
            show: true,
            data: resp.items,
            customer: `${target.name}(${target.phone.slice(-4)})`,
          };
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    sendSMS() {
      this.alert(['info', '현재 문자기능이 제공되지 않습니다. 서비스제공자에게 문의해주세요.']);
    },
    makeOptions(isSmsFilter = false) {
      const optionsForm = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sorts.sortBy,
        sortDesc: this.sorts.sortDesc,
        filters: [
          {
            filters: [
              {
                condition: 'inc',
                column: 'phone',
                value: this.search,
              },
              {
                where: 'or',
                condition: 'inc',
                column: 'name',
                value: this.search,
              },
              {
                where: 'or',
                condition: 'inc',
                column: 'memo',
                value: this.search,
              },
            ],
          },
          {
            where: 'and',
            condition: 'eq',
            column: 'manager',
            value: this.userInfo.id,
          },
        ],
      };
      const sorceSelectValue = this.selectFilters.sourceNo.value;
      if (sorceSelectValue !== -1) {
        optionsForm.filters.push({
          where: 'and',
          condition: 'eq',
          column: 'sourceNo',
          value: sorceSelectValue,
        });
      }
      const statusRadioValue = this.radioFilters.status.value;
      if (statusRadioValue !== -1) {
        optionsForm.filters.push({
          where: 'and',
          condition: 'eq',
          column: 'status',
          value: statusRadioValue,
        });
      }
      const amountRadioValue = this.radioFilters.amount.value;
      if (amountRadioValue === 1) {
        optionsForm.filters.push({
          where: 'and', condition: 'ge', column: 'amount', value: 0,
        });
      } else if (amountRadioValue === 0) {
        optionsForm.filters.push({
          where: 'and',
          filters: [
            { condition: 'none', column: 'amount' },
            {
              where: 'or', condition: 'lt', column: 'amount', value: 0,
            },
          ],
        });
      }
      const serviceRadioValue = this.radioFilters.service.value;
      if (serviceRadioValue === 1) {
        optionsForm.filters.push({
          where: 'and',
          filters: [
            { condition: 'some', column: 'serviceEnd' },
            {
              where: 'and', condition: 'some', column: 'serviceStart',
            },
          ],
        });
      } else if (serviceRadioValue === 0) {
        optionsForm.filters.push({
          where: 'and', condition: 'none', column: 'serviceStart',
        });
      } else if (serviceRadioValue === 2) {
        optionsForm.filters.push({
          where: 'and',
          filters: [
            { condition: 'none', column: 'serviceEnd' },
            {
              where: 'and', condition: 'some', column: 'serviceStart',
            },
          ],
        });
      }
      if (isSmsFilter === true) {
        optionsForm.filters.push({
          where: 'and',
          condition: 'eq',
          column: 'status',
          value: 1,
        });
      }
      return optionsForm;
    },
    getDefaultData() {
      this.loading = true;
      const errFunc = (resp) => {
        this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
        this.loading = false;
        this.lists = [];
      };
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (res) => {
        if (res.result === 'success') {
          this.groups = res.items.groups;
          this.$socket.emit('users.staffs.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: [],
            sortDesc: [],
            filters: [{ condition: 'eq', column: 'is_sales', value: 1 }],
          }, (resp) => {
            if (resp.result === 'success') {
              this.$socket.emit('contact.source.list.get', {
                page: 1,
                itemsPerPage: 0,
                sortBy: [],
                sortDesc: [],
                filters: [{ condition: 'eq', column: 'disabled', value: 0 }],
              }, (response) => {
                if (response.result === 'success') {
                  const sourceArray = [
                    { name: '전체', value: -1 },
                  ];
                  sourceArray.push(...response.items
                    .map((r) => ({ name: r.name, value: r.no })));
                  this.selectFilters.sourceNo.data = sourceArray;
                  this.getSummary();
                  this.progress(false);
                } else {
                  errFunc(response);
                }
              });
            } else {
              errFunc(resp);
            }
          });
        } else {
          errFunc(res);
        }
      });
    },
    getHistories(action, columnName, title, item) {
      this.progress(true);
      this.$socket.emit('log.get', {
        action,
        key: action === 'service.update' ? String(this.currentItem.serviceNo) : item.id,
      }, (resp) => {
        if (resp.result === 'success') {
          if (columnName === 'manager') {
            const staffs = [];
            this.$socket.emit('users.staffs.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: [],
              sortDesc: [],
              filters: [],
            }, (response) => {
              if (response.result === 'success') {
                response.items.forEach((r) => {
                  const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                  const user = {
                    id: r.id,
                    fullName: `${groupName}${r.name}`,
                    name: r.name,
                    group: groupName,
                  };
                  staffs.push(user);
                });
                const filterData = this.makeHistories[columnName](resp.items, staffs);
                this.historyDlg.data = filterData;
                this.historyDlg.show = true;
                this.historyDlg.title = title;
                this.progress(false);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
                console.error(resp);
              }
            });
          } else if (columnName === 'productNo') {
            this.$socket.emit('products.list.get', {
              page: 1,
              itemsPerPage: 0,
              sortBy: [],
              sortDesc: [],
              filters: [],
            }, (response) => {
              if (response.result === 'success') {
                const filterData = this.makeHistories[columnName](resp.items, response.items);
                this.historyDlg.data = filterData;
                this.historyDlg.show = true;
                this.historyDlg.title = title;
                this.progress(false);
              } else {
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
                console.error(resp);
              }
            });
          } else {
            const filterData = this.makeHistories[columnName](resp.items, this);
            this.historyDlg.data = filterData;
            this.historyDlg.show = true;
            this.historyDlg.title = title;
          }
          this.historyDlg.sub = `${item.name}(${item.phone.slice(-4)})`;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    checkFuncPermission(funcName) {
      try {
        let userPermission;
        if (funcName === 'sms' || funcName === 'regist') {
          userPermission = { ...this.userInfo.permission.salary.menu };
        } else {
          userPermission = { ...this.userInfo.permission.cs.menu.customer.func };
        }
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
    makeTelNumber(string) {
      let result = string.replaceAll('-', '');
      if (result.length === 8) {
        result = `${result.substring(0, 4)}-${result.substring(4, result.length)}`;
      } else if (result.length === 11) {
        result = `${
          result.substring(0, 3)
        }-${
          result.substring(3, 7)
        }-${result.substring(7, result.length)}`;
      }
      return result;
    },
    registCustomer() {
      this.progress(true);
      const data = { ...this.customerRegistDlg.data };
      if (data.sourceName === null || data.sourceName.trim().length <= 0) {
        delete data.sourceName;
      }
      const phone = this.makeTelNumber(data.phone);
      console.log(this.userInfo);
      this.$socket.emit('contact.list.get', {
        page: 1,
        itemsPerPage: 1,
        sortBy: [],
        sortDesc: [],
        filters: [
          {
            condition: 'inc',
            column: 'phone',
            value: phone,
          },
        ],
      }, (response) => {
        if (response.result === 'success' && response.items.length === 0) {
          this.$socket.emit('contact.add', {
            ...data,
            manager: this.userInfo.id,
            phone,
          }, (resp) => {
            if (resp.result === 'success') {
              this.progress(false);
              this.customerRegistDlg = {
                show: false,
                data: {
                  name: null,
                  phone: null,
                  sourceName: null,
                  manager: null,
                },
              };
              this.alert(['success', '고객이 추가되었습니다.']);
              this.getSummary();
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        } else if (response.result === 'success' && response.items.length !== 0) {
          this.alert(['error', `입력하신 번호(${phone})는 이미 등록된 번호입니다.`]);
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
          console.error(response);
          this.progress(false);
        }
      });
    },
    updateCustomerName(item, value) {
      const name = value.trim();
      if (name.length < 2 || name.length > 10) {
        this.alert(['error', '고객명은 2 ~ 10자여야 합니다.']);
      } else if (item.name === name) {
        this.alert(['error', '기존 고객명과 동일합니다.']);
      } else {
        this.$socket.emit('users.update', {
          items: [{
            id: item.id,
            name,
          }],
        }, (resp) => {
          if (resp.success >= 1) {
            this.alert(['success', '고객명이 변경되었습니다.']);
            this.getSummary(false);
          } else {
            this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
            console.error(resp);
          }
        });
      }
    },
  },
  watch: {
    sorts: {
      deep: true,
      handler() {
        this.getSummary(false);
      },
    },
    radioFilters: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    search: {
      handler() {
        this.getSummary();
      },
    },
    selectFilters: {
      deep: true,
      handler() {
        this.getSummary();
      },
    },
    itemsPerPage: {
      handler() {
        this.getSummary();
      },
    },
    page: {
      handler() {
        this.getSummary();
      },
    },
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getSummary = debounce(this.getSummary, 300);
          this.getDefaultData();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.sms-wrap {
  overflow: hidden;
  padding: 0px;
  .mid-section {
    width: 50%;
    padding: 20px;
    & + .mid-section {
      // margin-left: 10px;
      border-left: 1px solid #ccc;
    }
    // &.preview-section {
    //   display: flex;
    //   align-content: space-between;
    //   flex-wrap: wrap;
    // }
    .default-text {
      font-size: 0.9rem;
    }
    .preview-text {
      white-space: pre-line;
      padding: 10px;
      border-radius: 4px;
      font-size: 0.9rem;
      background-color: #f2f2f2;
    }
    .sub-tit {
      font-size: 1.1rem;
      font-weight: 500;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.edit-btn-wrap {
  margin-bottom: -20px;
}
.select-users {
  margin-top: 10px;
  padding-bottom: 6px;
  padding-left: 6px;
  border-radius: 4px;
  border: 1px solid #ececec;
  width: 100%;
  background-color: #fff;
}
.sales-selector {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 4px;
}
.empty-data {
  color: #aaa;
}
.memo-area {
  padding: 10px;
  background-color: #fff;
}
.memo-span {
  display: -webkit-box;
  text-align: left;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  -webkit-line-clamp: 2;
  line-height: 20px;
  max-height: 40px;
}
.log-wrap {
  padding: 15px;
  .update-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .key {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0;
      margin-right: 70px;
    }
    .value {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0;
    }
  }
  .update-btn-wrap {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px dashed #ccc;
  }

  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-bottom: 6px;
  }
  .log {
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding: 10px;
    .log-row {
      margin: 0;
      display: flex;
      justify-content: space-between;
      & + .log-row {
        margin-top: 4px;
      }
      span {
        font-size: 0.8rem;
        color: #555;
        & + span {
          margin-left: 60px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
.gray--text {
  color: #888;
}
.sms-log-wrap {
  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-bottom: 6px;
  }
  .sms-log {
    max-height: 400px;
    overflow: auto;
    background-color: #fff;
    border-top: 1px solid #ccc;
    .sms-log-row {
      margin: 0;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      padding-top: 10px;
      & + .sms-log-row {
        margin-top: 10px;
        border-top: 1px solid #ccc;
      }
      span {
        font-size: 0.8rem;
        color: #555;
      }
      span.bold-span {
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
.content-wrap {
  .text-input-wrap {
    width: 280px;
    margin-bottom: -10px;
    margin-top: 10px;
  }
  .content-box {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    .pay-list {
      .pay-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top: 0px;
        .pay-info {
          display: flex;
          align-items: center;
          .pay-text {
            color: #262626;
          }
        }
      }
    }
    .section {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #333;
      justify-content: space-between;
      & + .section,
      & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .pay-form {
        padding: 10px;
        min-width: 500px;
        max-width: 500px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top: -30px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .search-picker {
        width: 250px;
        max-width: 250px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top: 8px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
    }
    .section-wrap {
      & + .section,
      & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .section + .section {
        border-top: 1px dashed #ccc;
      }
    }
    .tit {
      font-size: 0.9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
  }
}
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top: 2px;
  width: 250px;
  display: flex;
  align-items: center;
}
</style>
